import React, {useState} from 'react';
import cl from './Menu.module.css'
import UnerButton from "../../UI/UnerButton/UnerButton";
import x from '../../Assets/icons/close.svg'
import hum from '../../Assets/icons/Humburger.svg'
import up from '../../Assets/icons/Arrow-up.svg'
const Menu = () => {

    const [menuOpen, setMenuOpen] = useState(false);
    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };



    return (
        <navbar className={cl.nav}>

            <div className={`${cl.openMenu} ${menuOpen ? cl.open : ''}`}>
                <div className={cl.menuItem}>
                    <a href="#about">О нас</a>
                    <a href="#partners">Партнерам</a>
                    <a href="#advantages">Преимущества</a>
                    <a href="#service">Сервис</a>
                    <a href="#contacts">Контакты</a>
                </div>
            </div>

            <UnerButton onClick={toggleMenu}><img className={cl.icon} src={menuOpen ? x : hum} alt="menu"/></UnerButton>
            <h1>UNER</h1>
            <UnerButton><img className={cl.icon} src={up} alt="Up"/></UnerButton>
        </navbar>
    );
};

export default Menu;