import React from 'react';
import cl from './UnerButton.module.css'
const UnerButton = ({children, onClick}) => {
    return (
        <button className={cl.button} onClick={onClick}>
            {children}
        </button>
    );
};

export default UnerButton;