import React from 'react';
import video from './Assets/lava_lamp_gaussian_blur.webm';
import './App.css';
import Menu from "./Components/Menu/Menu";

function App() {
    return (
        <div className="App">
            <Menu/>
            <video className='background' autoPlay loop muted>
                <source src={video} type="video/webm" />
                Your browser does not support the video tag.
            </video>
            <div className="overlay"></div>
        </div>
    );
}

export default App;
